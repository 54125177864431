<template>
  <div class="card">
    <!-- <p class="title">女儿墙</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}女儿墙</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.Thickness"
        input-align="right"
        label="厚度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写厚度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.EastHeight"
        input-align="right"
        label="东侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写东侧高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.WestHeight"
        input-align="right"
        label="西侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写西侧高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.SourthHeight"
        input-align="right"
        label="南侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写南侧高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.NorthSideHigh"
        input-align="right"
        label="北侧高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写北侧高度' }]"
        @focus="$event.target.select()"
      />
      <div class="item">
        <van-field label="是否有装饰物" input-align="right" readonly />
        <span @click.stop="decorationShow = true"
        >{{ form.TrueOrFalseDecorations }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="decorationShow">
          <li @click="chooseDecoration('是')">是</li>
          <li @click="chooseDecoration('否')">否</li>
        </ul>
      </div>
      <van-field
        @keydown="handleInput"
        v-if="form.TrueOrFalseDecorations == '是'"
        v-model.number="form.DecorationsHeight"
        input-align="right"
        label="装饰物高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写装饰物高度' }]"
      />
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span class="span" @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options widthOptions" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('装饰物可移除')">装饰物可移除</li>
          <li @click="chooseIsRemovable('均可移除')">均可移除</li>
          <li @click="chooseIsRemovable('不可移除')">不可移除</li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit" @click="del">删除</div>
        <!-- <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button> -->
        <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button>
        <span
          v-if="!isEdit"
          @click="editDetermine"
          class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { guid } from '@/plugins/guid';
import emitter from '../../plugins/eventBus'
import { Dialog } from 'vant';

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        Thickness: '', // 厚度
        EastHeight: '', // 东侧高度
        WestHeight: '', // 西侧高度
        SourthHeight: '', // 南侧高度
        NorthSideHigh: '', // 北侧高度
        TrueOrFalseDecorations: '否', // 是否有装饰物
        DecorationsHeight: '', // 装饰物高度
        TrueOrFalseRemove: '不可移除' // 是否可移除 1：装饰物可移除  2：均可移除  3：不可移除
      },
      decorationShow: false,
      IsRemovableTypeShow: false,
      isEdit: true,
      hort: {},
      a: 1
    })

    emitter.on('editPapert', (value) => {
      console.log(value, '----------------------')
      data.hort = value
      data.isEdit = false
      data.form.Thickness = data.hort.thickness / 100
      data.form.EastHeight = data.hort.eastHeight / 100
      data.form.WestHeight = data.hort.westHeight / 100
      data.form.SourthHeight = data.hort.sourthHeight / 100
      data.form.NorthSideHigh = data.hort.northHeight / 100
      if (data.hort.hasDecoration === 1) {
        data.form.TrueOrFalseDecorations = '是'
        data.form.DecorationsHeight = data.hort.decorationHeight / 100
        if (data.hort.isRemove === 1) {
          data.form.TrueOrFalseRemove = "装饰物可移除"
        } else if (data.hort.isRemove === 2) {
          data.form.TrueOrFalseRemove = "均可移除"
        } else if (data.hort.isRemove === 3) {
          data.form.TrueOrFalseRemove = "不可移除"
        }
      } else {
        data.form.trueOrFalseDecorations = '否'
      }
    })

    emitter.on('editstatePapert', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          const dataTo2d = {}
          data.hort.thickness = data.form.Thickness * 100
          data.hort.eastHeight = data.form.EastHeight * 100
          data.hort.westHeight = data.form.WestHeight * 100
          data.hort.sourthHeight = data.form.SourthHeight * 100
          data.hort.northHeight = data.form.NorthSideHigh * 100
          if (data.form.TrueOrFalseDecorations === '是') {
            data.hort.hasDecoration = 1
            data.hort.TrueOrFalseDecorations = true
            data.hort.decorationHeight = data.form.DecorationsHeight * 100
            if (data.form.TrueOrFalseRemove === "装饰物可移除" || data.form.TrueOrFalseRemove === "均可移除") {
              data.hort.isRemove = 1
            } else if (data.form.TrueOrFalseRemove === "不可移除") {
              data.hort.isRemove = 0
            }
            //  else if (data.form.TrueOrFalseRemove === "均可移除") {
            //   data.hort.TrueOrFalseRemove = 2
            // }
          } else {
            data.hort.hasDecoration = 0
            data.hort.TrueOrFalseDecorations = false
            if (data.form.TrueOrFalseRemove === "装饰物可移除" || data.form.TrueOrFalseRemove === "均可移除") {
              data.hort.isRemove = 1
            } else if (data.form.TrueOrFalseRemove === "不可移除") {
              data.hort.isRemove = 0
            }
          }
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑女儿墙" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑斜屋顶女儿墙" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, {name: '编辑内厦屋顶女儿墙'})
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.hort, {name: '编辑外厦带平台屋顶女儿墙'})
          }
          emit('popupClose', dataTo2d)
          data.isEdit = true
          data.form = {
            Thickness: '',
            EastHeight: '',
            WestHeight: '',
            SourthHeight: '',
            NorthSideHigh: '',
            TrueOrFalseDecorations: '否',
            DecorationsHeight: '',
            TrueOrFalseRemove: '不可移除'
          }
        }
      },
      handleBodyClick() {
        data.IsRemovableTypeShow = false
        data.decorationShow = false
      },
      chooseDecoration(item) {
        data.form.TrueOrFalseDecorations = item
        data.decorationShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },
      onSubmit() {
        window.frames[0].global_ObstacleList.forEach(val => {
          if (val.typeEnum === 1) {
            Dialog.alert({
              className: "no-title",
              message: "女儿墙只可有一个",
            })
          } else {
            methodsMap.fun()
          }
        });
        if (window.frames[0].global_ObstacleList.length === 0) {
          methodsMap.fun()
        }
      },
      fun() {
        if (data.isEdit === true && data.a === 1) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseDecorations' && i !== 'TrueOrFalseRemove') {
              data.form[i] = data.form[i] * 100
            }
          }
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, { name: "女儿墙" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, { name: "斜屋顶女儿墙" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, {name: '内厦屋顶女儿墙'})
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, {name: '外厦带平台屋顶女儿墙'})
          }

          emit('popupClose', dataTo2d)
          data.form = {
            Thickness: '',
            EastHeight: '',
            WestHeight: '',
            SourthHeight: '',
            NorthSideHigh: '',
            TrueOrFalseDecorations: '否',
            DecorationsHeight: '',
            TrueOrFalseRemove: '不可移除'
          }
        }
        data.a += 1
      },
      cancelEvent() {
        data.a = 0
        data.form = {
          Thickness: '',
          EastHeight: '',
          WestHeight: '',
          SourthHeight: '',
          NorthSideHigh: '',
          TrueOrFalseDecorations: '否',
          DecorationsHeight: '',
          TrueOrFalseRemove: '不可移除'
        }
        data.isEdit = true
        emit('popupClose')
      },
      del() {
        const dataTo2d = {}
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除女儿墙" })
        }

        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除斜屋顶女儿墙" })
        }

        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, {name: '删除内厦屋顶女儿墙'})
        }

        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, {name: '删除外厦带平台屋顶女儿墙'})
        }

        data.form = {
          Thickness: '',
          EastHeight: '',
          WestHeight: '',
          SourthHeight: '',
          NorthSideHigh: '',
          TrueOrFalseDecorations: '否',
          DecorationsHeight: '',
          TrueOrFalseRemove: '不可移除'
        }
        emit('popupClose', dataTo2d)
        data.isEdit = true
        data.a = 0
      },
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 404px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 52px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 122px;
    }
    .options {
      z-index: 1;
      width: 52px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 700;
      li {
        line-height: 20px;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .widthOptions {
      width: 122px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>

<template>
  <div class="card">
    <!-- <p class="title">树</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}树</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.TrunkHeight"
        input-align="right"
        label="树干高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写树干高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.TrunkDiameter"
        input-align="right"
        label="树干直径（m）"
        type="number"
        :rules="[{ required: true, message: '请填写树干直径' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.CanopyHeight"
        input-align="right"
        label="树冠高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写树冠高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.CanopyDiameter"
        input-align="right"
        label="树冠直径（m）"
        type="number"
        :rules="[{ required: true, message: '请填写树冠直径' }]"
        @focus="$event.target.select()"
      />

      <van-field
        @keydown="handleInput"
        v-model.number="form.XCoordinates"
        input-align="right"
        label="距西南角X坐标（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距西南角X坐标' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.YCoordinates"
        input-align="right"
        label="距西南角Y坐标（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距西南角Y坐标' }]"
        @focus="$event.target.select()"
      />
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options fourline" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('不可修剪')">不可修剪</li>
          <li @click="chooseIsRemovable('可修剪')">可修剪</li>
          <li @click="chooseIsRemovable('不可移除')">不可移除</li>
          <li @click="chooseIsRemovable('可移除')">可移除</li>
        </ul>
      </div>
      <van-field
        @keydown="handleInput"
        v-if="form.TrueOrFalseRemove == '可修剪'"
        v-model.number="form.BackDiameter"
        input-align="right"
        label="修剪后直径（m）"
        type="number"
        :rules="[{ required: true, message: '请填写修剪后直径' }]"
        @focus="$event.target.select()"
      />
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit" @click="del">删除</div>
        <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button>
        <span
          v-if="!isEdit"
          @click="editDetermine"
          class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { guid } from '@/plugins/guid';
import emitter from '../../plugins/eventBus'

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        TrunkHeight: '', // 树干高度（CM）
        TrunkDiameter: '', // 树干直径（CM）
        CanopyHeight: '', // 树冠高度（CM）
        CanopyDiameter: '', // 树冠直径（CM）
        XCoordinates: '', // 距西南角X坐标
        YCoordinates: '', // 距西南角Y坐标
        TrueOrFalseRemove: '不可修剪', // 是否可移除 1：可移除  2：不可移除  3：可修剪  4：不可修剪
        BackDiameter: '' // 修剪后直径
      },
      IsRemovableTypeShow: false,
      placeholder: "请选择",
      isEdit: true,
      hort: {},

    })

    emitter.on('editTree', (value) => {
      data.hort = value
      data.isEdit = false
      console.log(data.hort, '-----------------------------------------')

      data.form.TrunkHeight = data.hort.height1 / 100
      data.form.TrunkDiameter = data.hort.diam1 / 100
      data.form.CanopyHeight = data.hort.height2 / 100
      data.form.CanopyDiameter = data.hort.diam2 / 100
      data.form.XCoordinates = data.hort.X / 100
      data.form.YCoordinates = data.hort.Y / 100
      if (data.hort.isRemove === 1) {
        data.form.TrueOrFalseRemove = '可移除'
      } else if (data.hort.isRemove === 2) {
        data.form.TrueOrFalseRemove = '不可移除'
      } else if (data.hort.isRemove === 3) {
        data.form.TrueOrFalseRemove = '可修剪'
        var b = data.hort.BackDiameter / 100
        data.form.BackDiameter = b
      } else {
        data.form.TrueOrFalseRemove = '不可修剪'
      }
    })

    emitter.on('editstateTree', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          const dataTo2d = {}

          data.hort.height1 = data.form.TrunkHeight * 100
          data.hort.diam1 = data.form.TrunkDiameter * 100
          data.hort.height2 = data.form.CanopyHeight * 100
          data.hort.diam2 = data.form.CanopyDiameter * 100
          data.hort.X = data.form.XCoordinates * 100 / 100 * 100
          data.hort.Y = data.form.YCoordinates * 100 / 100 * 100

          if (data.form.TrueOrFalseRemove === '可移除') {
            data.hort.isRemove = 1
          } else if (data.form.TrueOrFalseRemove === '不可移除') {
            data.hort.isRemove = 2
          } else if (data.form.TrueOrFalseRemove === '可修剪') {
            data.hort.isRemove = 3
            var a = data.form.BackDiameter * 100
            data.hort.BackDiameter = a
          } else {
            data.hort.isRemove = 4
          }
          // Object.assign(dataTo2d, data.hort, { name: "编辑树" })
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑树" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑斜屋顶树" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑内厦屋顶树" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑外厦带平台屋顶树" })
          }

          emit('popupClose', dataTo2d)
          data.isEdit = true
          data.form = {
            TrunkHeight: '',
            TrunkDiameter: '',
            CanopyHeight: '',
            CanopyDiameter: '',
            XCoordinates: '',
            YCoordinates: '',
            TrueOrFalseRemove: '不可修剪',
            BackDiameter: ''
          }
        }
      },
      handleBodyClick() {
        data.IsRemovableTypeShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
        // data.placeholder = ""
      },

      onSubmit() {
        if (data.isEdit === true) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove') {
              data.form[i] = data.form[i] * 100
            }
          }
          // data.form.YCoordinates = data.form.YCoordinates * 100 / 100 * 100
          // Object.assign(dataTo2d, data.form, { name: "树" })
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, { name: "树" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, { name: "斜屋顶树" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, { name: "内厦屋顶树" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, { name: "外厦带平台屋顶树" })
          }

          emit('popupClose', dataTo2d)
          data.form = {
            TrunkHeight: '',
            TrunkDiameter: '',
            CanopyHeight: '',
            CanopyDiameter: '',
            XCoordinates: '',
            YCoordinates: '',
            TrueOrFalseRemove: '不可修剪',
            BackDiameter: ''
          }
        }
      },
      cancelEvent() {
        data.form = {
          TrunkHeight: '',
          TrunkDiameter: '',
          CanopyHeight: '',
          CanopyDiameter: '',
          XCoordinates: '',
          YCoordinates: '',
          TrueOrFalseRemove: '不可修剪',
          BackDiameter: ''
        }
        data.isEdit = true
        emit('popupClose')
      },
      del() {
        const dataTo2d = {}
        // Object.assign(dataTo2d, data.hort, { name: "删除树" })
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除树" })
        }

        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除斜屋顶树" })
        }

        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除内厦屋顶树" })
        }

        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除外厦带平台屋顶树" })
        }

        data.form = {
          TrunkHeight: '',
          TrunkDiameter: '',
          CanopyHeight: '',
          CanopyDiameter: '',
          XCoordinates: '',
          YCoordinates: '',
          TrueOrFalseRemove: '不可修剪',
          BackDiameter: ''
        }
        emit('popupClose', dataTo2d)
        data.isEdit = true
      },
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 448px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 94px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 144px;
    }
    .options {
      z-index: 1;
      width: 94px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      color: #c7c9cc;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .fourline {
      height: 88px;
    }
    .widthOptions {
      width: 144px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
::v-deep .van-field__control--right{
margin-right: 16px;
}
</style>

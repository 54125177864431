<template>
  <div class="card">
    <p class="title">窗</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.Width"
        input-align="right"
        label="窗宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写窗宽度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.High"
        input-align="right"
        label="窗高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写窗高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.RoofDistance"
        input-align="right"
        label="距屋顶距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距屋顶距离' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.WestSideDistance"
        input-align="right"
        label="距西侧距离（m）"
        type="number"
        :rules="[{ required: true, message: '请填写距西侧距离' }]"
        @focus="$event.target.select()"
      />
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <van-button native-type="submit" class="determine"> 确定 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { guid } from "@/plugins/guid";

export default {
  emits: ["popupClose"],
  setup(props, { emit }) {
    const globleData =
      getCurrentInstance().appContext.app.config.globalProperties.$globleData;
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        Type: 2, // 门窗类型 1：门  2：窗
        Width: "", // 窗宽度
        High: "", // 窗高度
        RoofDistance: "", // 距屋顶距离
        WestSideDistance: "", // 距西侧距离
        location: "",
      },
    });
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      onSubmit() {
        data.form.location = sessionStorage.getItem("view") - 0;
        const copyForm = JSON.parse(JSON.stringify(data.form));
        for (const i in copyForm) {
          if (
            i !== "id" &&
            i !== "roofID" &&
            i !== "Type" &&
            i !== "location"
          ) {
            copyForm[i] = copyForm[i] * 10;
          }
        }
        globleData.doorsAndWindowObstacleModels.push(copyForm);

        emit("popupClose");
      },
      cancelEvent() {
        emit("popupClose");
        data.form = {}
      },
    };

    return {
      ...toRefs(data),
      ...methodsMap,
    };
  },
};
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 312px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>

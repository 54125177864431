<template>
  <div class="card">
    <!-- <p class="title">烟囱</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}烟囱</p>
    <van-form
        @submit="onSubmit"
        :submit-on-enter="false"
        validate-trigger="onSubmit"
        scroll-to-error
        error-message-align="right"
    >
      <div class="item">
        <van-field label="烟囱形状" input-align="right" readonly />
        <span @click.stop="ChimneyShapeShow = true"
        >{{ form.ChimneyShapeType }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <button class="yancong">
          <ul class="options twoline" v-show="ChimneyShapeShow">
            <li @click="chooseChimneyShape('矩形烟囱')">矩形烟囱</li>
            <li @click="chooseChimneyShape('圆形烟囱')">圆形烟囱</li>
          </ul>
        </button>
      </div>
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span class="span" @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options options1 twoline" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('是')">是</li>
          <li @click="chooseIsRemovable('否')">否</li>
        </ul>
      </div>
      <div class="item">
        <van-field label="烟囱类型" input-align="right" readonly />
        <span @click.stop="ChimneyTypeShow = true"
        >{{ form.ChimneyType }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="ChimneyTypeShow">
          <li @click="chooseChimneyType('有烟烟囱')">有烟烟囱</li>
          <li @click="chooseChimneyType('无烟烟囱')">无烟烟囱</li>
        </ul>
      </div>
      <div v-if="form.ChimneyShapeType == '矩形烟囱'">
        <van-field
            @keydown="handleInput"
            v-model.number="form.EastWestLength"
            input-align="right"
            label="东西长度（m）"
            type="number"
            :rules="[{ required: true, message: '请填写东西长度' }]"
            @focus="$event.target.select()"
        />
        <van-field
            @keydown="handleInput"
            v-model.number="form.SourthNorthLength"
            input-align="right"
            label="南北宽度（m）"
            type="number"
            :rules="[{ required: true, message: '请填写南北宽度' }]"
            @focus="$event.target.select()"
        />
      </div>
      <van-field
          @keydown="handleInput"
          v-if="form.ChimneyShapeType == '圆形烟囱'"
          v-model.number="form.ChimneyDiam"
          input-align="right"
          label="烟囱直径（m）"
          type="number"
          :rules="[{ required: true, message: '请填写烟囱直径' }]"
          @focus="$event.target.select()"
      />
      <van-field
          @keydown="handleInput"
          v-model.number="form.CurrentHeight"
          input-align="right"
          label="当前高度（m）"
          type="number"
          :rules="[{ required: true, message: '请填写当前高度' }]"
          @focus="$event.target.select()"
      />
      <div class="item">
        <van-field ref="fieldRef" label="是否可调节" input-align="right" readonly />
        <span @click.stop="IsAdjustableShow = true"
        >{{ form.TrueOrFalseAdjustable }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options twoline" v-show="IsAdjustableShow">
          <li @click="chooseIsAdjustable('可调节')">可调节</li>
          <li @click="chooseIsAdjustable('不可调节')">不可调节</li>
        </ul>
      </div>
      <van-field
          @keydown="handleInput"
          v-if="form.TrueOrFalseAdjustable == '可调节'"
          v-model.number="form.RearHeight"
          input-align="right"
          label="调节后高度"
          type="number"
          :rules="[{ required: true, message: '请填写调节后高度' }]"
          @focus="$event.target.select()"
      />
      <van-field
          @keydown="handleInput"
          v-model.number="form.XCoordinates"
          input-align="right"
          label="距西南角X坐标（m）"
          type="number"
          :rules="[{ required: true, message: '请填写距西南角X坐标' }]"
          @focus="$event.target.select()"
      />
      <van-field
          @keydown="handleInput"
          v-model.number="form.YCoordinates"
          input-align="right"
          label="距西南角Y坐标（m）"
          type="number"
          :rules="[{ required: true, message: '请填写距西南角Y坐标' }]"
          @focus="$event.target.select()"
      />
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit && state" @click="del">删除</div>
        <van-button v-if="isEdit && state" native-type="submit" class="determine"> 确定 </van-button>
        <span
            v-if="!isEdit && state"
            @click="editDetermine"
            class="determine"
        >确定</span
        >

        <div class="cancel" v-if="!isEdit && !state" @click="del">删除</div>
        <van-button v-if="isEdit && !state" native-type="submit" class="determine"> 确定 </van-button>
        <span
            v-if="!isEdit && !state"
            @click="editDetermine"
            class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from 'vue'
import { guid } from '@/plugins/guid';
import emitter from '../../plugins/eventBus'

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    const fieldRef = ref(null)
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        ChimneyShapeType: '矩形烟囱', // 烟囱形状 1：矩形烟囱 2：圆形烟囱
        TrueOrFalseRemove: '否', // 是否可移除1：可移除  2：不可移除
        ChimneyType: '有烟烟囱', // 烟囱类型 1：有烟烟囱 2：无烟烟囱
        EastWestLength: '', // 东西长度（CM）
        SourthNorthLength: '', // 南北宽度（CM）
        ChimneyDiam: '', // 烟囱直径
        CurrentHeight: '', // 当前高度（CM）
        TrueOrFalseAdjustable: '不可调节', // 是否可调节1：可调节  2：不可调节
        RearHeight: '', // 调节后高度
        XCoordinates: '', // 距西南角X坐标
        YCoordinates: '' // 距西南角Y坐标
      },
      ChimneyTypeShow: false,
      ChimneyShapeShow: false,
      IsRemovableTypeShow: false,
      IsAdjustableShow: false,
      placeholder: "请选择",
      isEdit: true,
      hort: {},
      state: true

    })

    emitter.on('editRectChimney', (value) => {
      data.hort = value
      data.isEdit = false

      if (data.hort.typeEnum === 2) {
        data.form.ChimneyShapeType = '矩形烟囱'
      } else if (data.hort.typeEnum === 3) {
        data.form.ChimneyShapeType = '圆形烟囱'
      }
      if (data.hort.isRemove === 0) {
        data.form.TrueOrFalseRemove = '否'
      } else {
        data.form.TrueOrFalseRemove = '是'
      }
      if (data.hort.chimneyType === 1) {
        data.form.ChimneyType = '有烟烟囱'
      } else {
        data.form.ChimneyType = '无烟烟囱'
      }
      if (data.hort.isChage === 1) {
        data.form.TrueOrFalseAdjustable = '可调节'
        data.form.RearHeight = data.hort.chageHeight / 100
      } else {
        data.form.TrueOrFalseAdjustable = '不可调节'
      }
      data.form.XCoordinates = data.hort.X / 100
      data.form.YCoordinates = data.hort.Y / 100
      data.form.EastWestLength = data.hort.eastwestDistance / 100
      data.form.SourthNorthLength = data.hort.sourthnorthDistance / 100
      data.form.ChimneyDiam = data.hort.chimneyDiam / 100
      data.form.CurrentHeight = data.hort.currentHeight / 100
    })

    emitter.on('editstateChimney', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          const dataTo2d = {}
          if (data.form.ChimneyShapeType === '矩形烟囱') {
            dataTo2d.name = '矩形烟囱'
          } else {
            dataTo2d.name = '圆形烟囱'
          }

          if (data.form.ChimneyShapeType === '矩形烟囱') {
            data.hort.typeEnum = 2
          } else if (data.form.ChimneyShapeType === '圆形烟囱') {
            data.hort.typeEnum = 3
          }
          if (data.form.TrueOrFalseRemove === '否') {
            data.hort.isRemove = 0
          } else {
            data.hort.isRemove = 1
          }
          if (data.form.ChimneyType === '有烟烟囱') {
            data.hort.chimneyType = 1
          } else {
            data.hort.chimneyType = 2
          }
          if (data.form.TrueOrFalseAdjustable === '可调节') {
            data.hort.isChage = 1
            data.hort.chageHeight = data.form.RearHeight * 100
          } else {
            data.hort.isChage = 0
          }
          data.hort.X = data.form.XCoordinates * 100
          data.hort.Y = data.form.YCoordinates * 100
          data.hort.eastwestDistance = data.form.EastWestLength * 100
          data.hort.sourthnorthDistance = data.form.SourthNorthLength * 100
          data.hort.chimneyDiam = data.form.ChimneyDiam * 100
          data.hort.currentHeight = data.form.CurrentHeight * 100

          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, data.state ? { name: "编辑矩形烟囱" } : { name: "编辑圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, data.state ? { name: "编辑斜屋顶矩形烟囱" } : { name: "编辑斜屋顶圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, data.state ? { name: "编辑内厦屋顶矩形烟囱" } : { name: "编辑内厦屋顶圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.hort, data.state ? { name: "编辑外厦带平台屋顶矩形烟囱" } : { name: "编辑外厦带平台屋顶圆形烟囱" })
          }
          emit('popupClose', dataTo2d)

          data.isEdit = true
          data.form = {
            ChimneyShapeType: '矩形烟囱',
            TrueOrFalseRemove: '否',
            ChimneyType: '有烟烟囱',
            EastWestLength: '',
            SourthNorthLength: '',
            ChimneyDiam: '',
            CurrentHeight: '',
            TrueOrFalseAdjustable: '不可调节',
            RearHeight: '',
            XCoordinates: '',
            YCoordinates: ''
          }
        }
      },
      handleBodyClick() {
        data.ChimneyTypeShow = false
        data.ChimneyShapeShow = false
        data.IsRemovableTypeShow = false
        data.IsAdjustableShow = false
      },
      chooseChimneyShape(item) {
        console.log(item, '什么类型烟囱')
        if (item === '矩形烟囱') {
          data.state = true
        } else {
          data.state = false
        }
        data.form.ChimneyShapeType = item
        data.ChimneyShapeShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },
      chooseChimneyType(item) {
        data.form.ChimneyType = item
        data.ChimneyTypeShow = false
      },
      chooseIsAdjustable(item) {
        data.form.TrueOrFalseAdjustable = item
        data.IsAdjustableShow = false
        // data.placeholder = ""
      },
      onSubmit() {
        if (data.isEdit === true) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'ChimneyShapeType' && i !== 'id' && i !== 'roofID' && i !== 'AisleType' && i !== 'TrueOrFalseRemove' && i !== 'ChimneyType' && i !== 'TrueOrFalseAdjustable') {
              data.form[i] = data.form[i] * 100
            }
          }
          if (data.form.ChimneyShapeType === '矩形烟囱') {
            dataTo2d.name = '矩形烟囱'
          } else {
            dataTo2d.name = '圆形烟囱'
          }
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, data.state ? { name: "矩形烟囱" } : { name: "圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, data.state ? { name: "斜屋顶矩形烟囱" } : { name: "斜屋顶圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, data.state ? { name: "内厦屋顶矩形烟囱" } : { name: "内厦屋顶圆形烟囱" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, data.state ? { name: "外厦带平台屋顶矩形烟囱" } : { name: "外厦带平台屋顶圆形烟囱" })
          }
          emit('popupClose', dataTo2d)
          data.form = {
            ChimneyShapeType: '矩形烟囱',
            TrueOrFalseRemove: '否',
            ChimneyType: '有烟烟囱',
            EastWestLength: '',
            SourthNorthLength: '',
            ChimneyDiam: '',
            CurrentHeight: '',
            TrueOrFalseAdjustable: '不可调节',
            RearHeight: '',
            XCoordinates: '',
            YCoordinates: ''
          }
        }
      },
      cancelEvent() {
        data.form = {
          ChimneyShapeType: '矩形烟囱',
          TrueOrFalseRemove: '否',
          ChimneyType: '有烟烟囱',
          EastWestLength: '',
          SourthNorthLength: '',
          ChimneyDiam: '',
          CurrentHeight: '',
          TrueOrFalseAdjustable: '不可调节',
          RearHeight: '',
          XCoordinates: '',
          YCoordinates: ''
        }
        emit('popupClose')
        data.isEdit = true
      },
      del() {
        const dataTo2d = {}
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, data.state ? { name: "删除矩形烟囱" } : { name: "删除圆形烟囱" })
        }

        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, data.state ? { name: "删除斜屋顶矩形烟囱" } : { name: "删除斜屋顶圆形烟囱" })
        }

        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, data.state ? { name: "删除内厦屋顶矩形烟囱" } : { name: "删除内厦屋顶圆形烟囱" })
        }

        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, data.state ? { name: "删除外厦带平台屋顶矩形烟囱" } : { name: "删除外厦带平台屋顶圆形烟囱" })
        }
        data.form = {
          ChimneyShapeType: '矩形烟囱',
          TrueOrFalseRemove: '否',
          ChimneyType: '有烟烟囱',
          EastWestLength: '',
          SourthNorthLength: '',
          ChimneyDiam: '',
          CurrentHeight: '',
          TrueOrFalseAdjustable: '不可调节',
          RearHeight: '',
          XCoordinates: '',
          YCoordinates: ''
        }
        emit('popupClose', dataTo2d)
        data.isEdit = true
      },

    }

    return {
      ...toRefs(data),
      ...methodsMap,
      fieldRef
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 488px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;

  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 94px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 52px;
    }
    .options {
      z-index: 1;
      width: 94px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      color: #c7c9cc;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .options1 {
      width: 52px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
::v-deep .van-field__control--right{
  margin-right: 16px;
}
</style>

<template>
  <div class="card">
    <!-- <p class="title">电线</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}电线</p>
    <van-form
      @keydown="handleInput"
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.WireHeight"
        input-align="right"
        label="突出高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写突出高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.WireDiameter"
        input-align="right"
        label="电线线径（m）"
        type="number"
        :rules="[{ required: true, message: '请填写电线线径' }]"
        @focus="$event.target.select()"
      />
      <div class="item">
        <van-field label="电压等级" input-align="right" readonly />
        <span @click.stop="VoltageLevelTypeShow = true"
        >{{ form.Voltage }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options" v-show="VoltageLevelTypeShow">
          <li @click="chooseVoltageLevel('220V')">220V</li>
          <li @click="chooseVoltageLevel('380V')">380V</li>
          <li @click="chooseVoltageLevel('10KV')">10KV</li>
        </ul>
      </div>
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span class="span1" @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options widthSpan1 twoline" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('是')">是</li>
          <li @click="chooseIsRemovable('否')">否</li>
        </ul>
      </div>
      <div class="item">
        <van-field label="电线类型" input-align="right" readonly />
        <span class="span" @click.stop="WireTypeShow = true"
        >{{ form.WireType }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options widthOptions" v-show="WireTypeShow">
          <li @click="chooseWireType('屋外电线')">屋外电线</li>
          <li @click="chooseWireType('穿墙角电线')">穿墙角电线</li>
          <li @click="chooseWireType('横穿电线')">横穿电线</li>
        </ul>
      </div>
      <div v-if="form.WireType !== '横穿电线'">
        <div class="item">
          <van-field label="电线位置" input-align="right" readonly />
          <span @click.stop="WireLocationShow = true"
          >{{ form.WireLocation }}
            <img src="@/assets/images/下拉1.png" alt="" />
          </span>
          <ul
            class="options"
            v-show="WireLocationShow && form.WireType == '屋外电线'"
          >
            <li @click="chooseWireLocation('东侧')">东侧</li>
            <li @click="chooseWireLocation('南侧')">南侧</li>
            <li @click="chooseWireLocation('西侧')">西侧</li>
          </ul>
          <ul
            class="options fourline"
            v-show="WireLocationShow && form.WireType == '穿墙角电线'"
          >
            <li @click="chooseWireLocation('东南角')">东南角</li>
            <li @click="chooseWireLocation('东北角')">东北角</li>
            <li @click="chooseWireLocation('西南角')">西南角</li>
            <li @click="chooseWireLocation('西北角')">西北角</li>
          </ul>
        </div>
        <div v-if="form.WireType == '屋外电线'">
          <van-field
            @keydown="handleInput"
            v-model.number="distance1"
            :label="
              form.WireLocation == '南侧'
                ? '距西南角（m）'
                : form.WireLocation == '东侧'
                  ? '距东南角（m）'
                  : '距西南角（m）'
            "
            input-align="right"
            type="number"
            @focus="$event.target.select()"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请填写距墙角距离'
              }
            ]"
          />
          <van-field
            @keydown="handleInput"
            v-model.number="distance2"
            :label="
              form.WireLocation == '南侧'
                ? '距东南角（m）'
                : form.WireLocation == '东侧'
                  ? '距东北角（m）'
                  : '距西北角（m）'
            "
            input-align="right"
            type="number"
            @focus="$event.target.select()"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请填写距墙角距离'
              }
            ]"
          />
        </div>
        <div v-if="form.WireType == '穿墙角电线'">
          <van-field
            @keydown="handleInput"
            v-model.number="distance1"
            :label="'距' + form.WireLocation + 'X距离' + '(m)'"
            input-align="right"
            type="number"
            @focus="$event.target.select()"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请填写距墙角距离'
              }
            ]"
          />
          <van-field
            @keydown="handleInput"
            v-model.number="distance2"
            :label="'距' + form.WireLocation + 'Y距离' + '(m)'"
            input-align="right"
            type="number"
            @focus="$event.target.select()"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请填写距墙角距离'
              }
            ]"
          />
        </div>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit" @click="del">删除</div>
        <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button>
        <span
          v-if="!isEdit"
          @click="editDetermine"
          class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { guid } from '@/plugins/guid';
import emitter from '../../plugins/eventBus'

export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        WireHeight: '', // 突出高度（CM）
        WireDiameter: '', // 电线线径（CM）
        Voltage: '220V', // 电压等级
        TrueOrFalseRemove: '否', // 是否可移除 1：可移除  2：不可移除
        WireType: '屋外电线', // 电线类型 1：屋外电线  2：屋角电线  3：横穿电线
        WireLocation: '东侧', // 电线位置 1：东侧  2：西侧  3：南侧  4：东南角  5：东北角  6：西南角  7：西北角
      },

      distance1: '', // 距墙角距离1
      distance2: '', // 距墙角距离2
      IsRemovableTypeShow: false,
      VoltageLevelTypeShow: false,
      WireTypeShow: false,
      WireLocationShow: false,
      isEdit: true,
      hort: {},
    })

    emitter.on('editWireObstacle', (value) => {
      data.hort = value
      data.isEdit = false
      data.form.WireHeight = data.hort.height / 100
      data.form.WireDiameter = data.hort.diam / 100
      data.form.Voltage = data.hort.wireRange
      data.distance1 = data.hort.value1 / 100
      data.distance2 = data.hort.value2 / 100

      if (data.hort.isRemove === 1) {
        data.form.TrueOrFalseRemove = '是'
      } else {
        data.form.TrueOrFalseRemove = '否'
      }
      if (data.hort.wireType === 1) {
        data.form.WireType = '屋外电线'
        if (data.hort.direction === 1) {
          data.form.WireLocation = '东侧'
        } else if (data.hort.direction === 2) {
          data.form.WireLocation = '南侧'
        } else {
          data.form.WireLocation = '西侧'
        }
      } else if (data.hort.wireType === 2) {
        data.form.WireType = '穿墙角电线'
        if (data.hort.direction === 1) {
          data.form.WireLocation = '东南角'
        } else if (data.hort.direction === 2 || data.hort.direction === 5) {
          data.form.WireLocation = '东北角'
        } else if (data.hort.direction === 3 || data.hort.direction === 6) {
          data.form.WireLocation = '西南角'
        } else if (data.hort.direction === 4 || data.hort.direction === 7) {
          data.form.WireLocation = '西北角'
        }
      } else {
        data.form.WireType = '横穿电线'
      }
    })

    emitter.on('editstateWireObstacle', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    onMounted(() => {
      window.addEventListener('click', methodsMap.handleBodyClick)
    })
    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          if (data.form.WireType === '屋外电线') {
            if (data.form.WireLocation === '南侧') {
              data.form.SouthwestCorner = data.distance1 * 100
              data.form.SoutheastCorner = data.distance2 * 100
            } else if (data.form.WireLocation === '东侧') {
              data.form.SoutheastCorner = data.distance1 * 100
              data.form.NortheastCorner = data.distance2 * 100
            } else {
              data.form.SouthwestCorner = data.distance1 * 100
              data.form.NorthwestCorner = data.distance2 * 100
            }
            if (data.form.WireLocation === '东侧') {
              data.hort.direction = 1
            } else if (data.form.WireLocation === '南侧') {
              data.hort.direction = 2
            } else {
              data.hort.direction = 3
            }
          }
          if (data.form.WireType === '穿墙角电线') {
            data.form.XDistance = data.distance1 * 100
            data.form.YDistance = data.distance2 * 100
            if (data.form.WireLocation === '东南角') {
              data.hort.direction = 1
            } else if (data.form.WireLocation === '东北角') {
              data.hort.direction = 2
            } else if (data.form.WireLocation === '西南角') {
              data.hort.direction = 3
            } else {
              data.hort.direction = 4
            }
          }
          const dataTo2d = {}

          data.hort.height = data.form.WireHeight * 100
          data.hort.diam = data.form.WireDiameter * 100
          data.hort.wireRange = data.form.Voltage * 100
          data.hort.value1 = data.distance1 * 100
          data.hort.value2 = data.distance2 * 100
          data.hort.wireRange = data.form.Voltage

          if (data.form.TrueOrFalseRemove === '是') {
            data.hort.isRemove = 1
          } else {
            data.hort.isRemove = 0
          }
          if (data.form.WireType === '屋外电线') {
            data.hort.wireType = 1
          } else if (data.form.WireType === '穿墙角电线') {
            data.hort.wireType = 2
          } else {
            data.hort.wireType = 3
          }

          // Object.assign(dataTo2d, data.hort, { name: "编辑电线" })
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑电线" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑斜屋顶电线" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑内厦屋顶电线" })
          }

          emit('popupClose', dataTo2d)
          data.isEdit = true
          data.form = {
            WireHeight: '',
            WireDiameter: '',
            Voltage: '220V',
            TrueOrFalseRemove: '否',
            WireType: '屋外电线',
            WireLocation: '东侧',
          }
          data.distance1 = ''
          data.distance2 = ''
        }
      },
      handleBodyClick() {
        data.IsRemovableTypeShow = false
        data.VoltageLevelTypeShow = false
        data.WireTypeShow = false
        data.WireLocationShow = false
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },
      chooseVoltageLevel(item) {
        data.form.Voltage = item
        data.VoltageLevelTypeShow = false
      },
      chooseWireType(item) {
        data.form.WireType = item
        data.WireTypeShow = false
        item === '屋外电线'
          ? (data.form.WireLocation = '东侧')
          : (data.form.WireLocation = '东北角')
      },
      chooseWireLocation(item) {
        data.form.WireLocation = item
        data.WireLocationShow = false
      },
      onSubmit() {
        if (data.isEdit === true) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'id' && i !== 'roofID' && i !== 'Voltage' && i !== 'TrueOrFalseRemove' && i !== 'WireType' && i !== 'WireLocation') {
              data.form[i] = data.form[i] * 100
            }
          }
          // Object.assign(dataTo2d, data.form, { distance1: data.distance1 }, { distance2: data.distance2 }, { name: "电线" })
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, { distance1: data.distance1 * 100 }, { distance2: data.distance2 * 100 }, { name: "电线" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, { distance1: data.distance1 * 100 }, { distance2: data.distance2 * 100 }, { name: "斜屋顶电线" })
          }

          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, { distance1: data.distance1 * 100 }, { distance2: data.distance2 * 100 }, { name: "内厦屋顶电线" })
          }

          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, { distance1: data.distance1 * 100 }, { distance2: data.distance2 * 100 }, { name: "外厦带平台屋顶电线" })
          }

          emit('popupClose', dataTo2d)
          data.form = {
            WireHeight: '',
            WireDiameter: '',
            Voltage: '220V',
            TrueOrFalseRemove: '否',
            WireType: '屋外电线',
            WireLocation: '东侧',
          }
          data.distance1 = ''
          data.distance2 = ''
        }
      },
      cancelEvent() {
        data.form = {
          WireHeight: '',
          WireDiameter: '',
          Voltage: '220V',
          TrueOrFalseRemove: '否',
          WireType: '屋外电线',
          WireLocation: '东侧',
        }
        data.distance1 = ''
        data.distance2 = ''
        data.isEdit = true
        emit('popupClose')
      },
      del() {
        const dataTo2d = {}
        // Object.assign(dataTo2d, data.hort, { name: "删除电线" })
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除电线" })
        }

        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除斜屋顶电线" })
        }

        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除内厦屋顶电线" })
        }

        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除外厦带平台屋顶电线" })
        }

        data.form = {
          WireHeight: '',
          WireDiameter: '',
          Voltage: '220V',
          TrueOrFalseRemove: '否',
          WireType: '屋外电线',
          WireLocation: '东侧',
        }
        data.distance1 = ''
        data.distance2 = ''
        emit('popupClose', dataTo2d)
        data.isEdit = true
      },

    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 360px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 80px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .span {
      width: 108px;
    }
    .span1 {
      width: 52px;
    }
    .options {
      z-index: 1;
      width: 80px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      color: #c7c9cc;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
    .fourline {
      height: 88px;
    }
    .widthOptions {
      width: 108px;
    }
    .widthSpan1 {
      width: 52px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
